<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Alterar Senha</h3>
      </div>
    </div>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form role="form" @submit.prevent="handleSubmit(resetPassword)">
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-4">
            <base-input
              type="password"
              label="Senha Atual"
              name="Senha Atual"
              placeholder="Senha Atual"
              :rules="{required: true, min: 6}"
              v-model="password.oldPassword"
            >
            </base-input>
          </div>
          <div class="col-lg-4">
            <base-input
              type="password"
              label="Nova Senha"
              name="Nova Senha"
              placeholder="Nova Senha"
              :rules="{required: true, min: 6}"
              v-model="password.newPassword"
            >
            </base-input>
          </div>
          <div class="col-lg-4">
            <base-input
              type="password"
              name="Confirme a Senha"
              label="Confirme a Senha"
              placeholder="Confirme a Senha"
              :rules="{required: true, min: 6}"
              v-model="confirmPassword"
            >
            </base-input>
          </div>
          <div class="col-lg-12">
            <base-button type="primary" native-type="submit" class="btn btn-primary btn-fill btn-wd">Salvar Senha</base-button>
          </div>
        </div>
      </div>

    </form>
    </validation-observer>
  </card>
</template>
<script>
  import {postWs} from "@/ws.service";

  export default {
    data() {
      return {
        confirmPassword: '',
        password: {
          oldPassword: '',
          newPassword: '',
        }
      };
    },
    methods: {
      resetPassword() {
        postWs("/acc/reset-password", true, null, this.password,
          this.changeSuccess, function () {
            this.$swal({
              title: 'Falha!',
              text: 'Falha ao salvar nova senha, favor tentar novamente mais tarde.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger btn-fill'
              },
              timer: 5000,
              icon: 'error'
            })
          })
      },
      changeSuccess() {
        this.$swal({
          title: 'Sucesso!',
          text: 'Senha alterada com sucesso.',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill'
          },
          timer: 5000,
          icon: 'success'
        })
        this.password.oldPassword = ''
        this.password.newPassword = ''
        this.confirmPassword = ''
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
    }
  };
</script>
<style></style>
