<template>
  <div>
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-4 order-xl-2">
          <div class="card card-profile">
            <div class="row justify-content-center mt-5">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#" class="fileContainer">
                    <img class="rounded-circle" :src="loadDocSrc()" v-if="this.showAvatar">
                    <img class="rounded-circle" src="/img/theme/user.png" v-else alt="...">
                    <input type="file" id="file" ref="file" accept=".png, .jpg, .jpeg"
                           v-on:change="handleFileUpload()"/>
                  </a>
                </div>
              </div>
            </div>
            <br/><br/>
            <div class="card-body pt-0 mt-5">
              <div class="text-center">
                <br/>
                <h5>
                  {{this.account.name}}
                </h5>
                <h5 class="h3 text-primary">
                  {{usernameDisplay}}
                </h5>
                <br/>
                <div class="h5 font-weight-300">
                  <p class="text-center" style="color: #12a500; text-transform: uppercase"
                     v-show="this.account.accountDocumentStatus == 'APPROVED'"
                     v-html="'<i class=\'fas fa-check-circle\'></i> <b> '+this.statusAccount+'</b>'"/>
                  <p class="text-center" style="color: #ff0005; text-transform: uppercase"
                     v-show="this.account.accountDocumentStatus != 'APPROVED'"
                     v-html="'<i class=\'fas fa-exclamation-circle\'> <b> '+this.statusAccount+'</b>'"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8 order-xl-1">

          <upload-doc :to-finish="loadAccount" v-show="showDocumentsForm">
          </upload-doc>

          <card v-if="!showProfileForm
                      &&this.account.accountDocumentStatus == 'PENDING_DOCUMENT'
                      &&(this.account.name == null || this.account.name == 'null' || this.account.name == '')"
                header-classes="bg-transparent"
                body-classes="px-lg-7"
                class="card-pricing border-0 text-center mb-4">

            <!--Header-->
            <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">
              1º Passo - Preencher Perfil
            </h4>

            <!--Body-->
            <ul class="list-unstyled my-4">
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                      Para você usufruir de todas as funcionalidades e benefícios da sua conta Olimpo Pay, é necessário você seguir alguns passos.
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                      Primeiro você deve preencher um formulário com os seus dados, clicando no botão abaixo.
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row">
              <div class="text-center col-lg-12">
                <button @click="openProfileForm" class="btn btn-primary btn-fill btn-wd">
                  Preencher Formulário de Perfil
                </button>
              </div>
            </div>
          </card>

          <card v-if="!showDocumentsForm
                      &&this.account.accountDocumentStatus == 'PENDING_DOCUMENT'
                      &&(this.account.name != null && this.account.name != 'null' && this.account.name != '')"
                header-classes="bg-transparent"
                body-classes="px-lg-7"
                class="card-pricing border-0 text-center mb-4">

            <!--Header-->
            <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">
              2º Passo - Enviar Documentos
            </h4>

            <!--Body-->
            <ul class="list-unstyled my-4">
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                      Agora que você preencheu o formulário com os seus dados, nós precisamos que você nos envie a sua documentação para que seja validada pela nossa equipe.
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                      Clique no botão abaixo e envie a documentação solicitada.
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row">
              <div class="text-center col-lg-12">
                <button @click="openDocumentsForm" class="btn btn-primary btn-fill btn-wd">
                  Incluir e Enviar Documentos
                </button>
              </div>
            </div>
          </card>

          <card v-if="this.account.accountDocumentStatus == 'PENDING_VALIDATION'"
                header-classes="bg-transparent"
                body-classes="px-lg-7"
                class="card-pricing border-0 text-center mb-4">

            <!--Header-->
            <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">
              3º Passo - Aguardar Aprovação
            </h4>

            <!--Body-->
            <ul class="list-unstyled my-4">
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                      Você preencheu todos os dados e enviou todos os documentos necessários para aprovação da sua conta.
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                        Nossa equipe está analisando o seu cadastro e em até 48h iremos responder a sua solicitação de aprovação.
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                       Se todos os seus dados e documentos estiverem de acordo com as nossas políticas, você será liberado para aproveitar todas as nossas vantagens.
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                      Você pode acompanhar o andamento da sua solicitação nessa mesma tela.
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div class="d-flex text-left">
                  <div>
                    <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                      <i class="fas fa-asterisk"></i>
                    </div>
                  </div>
                  <div>
                    <span class="pl-2 text-sm">
                      Muito obrigado por fazer parte da Olimpo Pay.
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </card>

          <reset-password v-if="(showProfileForm && profileFormFieldsDisabled)
                              ||(!showProfileForm && !showDocumentsForm)"></reset-password>

          <div class="card" v-if="showProfileForm">
            <div class="card-header">
              <div class="col-8">
                <h3 class="mb-0">Perfil</h3>
              </div>
            </div>
            <div class="card-body">
              <validation-observer ref="formValidator">
                <form role="form" @submit.prevent="saveAccount">
                  <div class="row">
                    <div class="col-md-5">
                      <base-input type="text"
                                  label="Usuário"
                                  :disabled="true"
                                  :rules="{required: true}"
                                  v-model="usernameDisplay">
                      </base-input>
                    </div>
                    <div class="col-md-7">
                      <base-input type="email"
                                  label="E-mail"
                                  :disabled="true"
                                  v-model="email">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <base-input type="tel"
                                  label="Celular"
                                  v-mask="['(##) #####-####']"
                                  v-model="account.phoneNumber1"
                                  :rules="{required: true, min: 14}"
                                  :disabled="profileFormFieldsDisabled">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <base-input type="tel"
                                  label="Telefone"
                                  v-mask="['(##) ####-####', '(##) #####-####']"
                                  v-model="account.phoneNumber2"
                                  :disabled="profileFormFieldsDisabled"
                                  key="telefone">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input type="text"
                                  maxlength="70"
                                  label="Nome Completo"
                                  v-model="account.name"
                                  :rules="{required: true, min: 6}"
                                  :disabled="profileFormFieldsDisabled">
                      </base-input>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 30px">
                    <div class="col-md-5">
                      <base-input label="Tipo de Pessoa" key="tipo-pessoa">
                        <el-select size="large" name="Tido de Pessoa"
                                   placeholder="Selecionar"
                                   v-on:change="changePersonType"
                                   v-model="account.personType"
                                   :disabled="profileFormFieldsDisabled">
                          <el-option class="select-success"
                                     value="NATURAL_PERSON"
                                     label="Pessoa Física"/>
                          <el-option class="select-info"
                                     value="LEGAL_PERSON"
                                     label="Pessoa Jurídica"/>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-7">
                      <base-input label="CPF"
                                  type="tel"
                                  v-mask="['###.###.###-##']"
                                  name="CPF/CNPJ"
                                  :rules="{required: true}"
                                  v-model="account.socialNumber"
                                  key="social-number-cpf"
                                  :disabled="profileFormFieldsDisabled"
                                  v-if="account.personType=='NATURAL_PERSON'">
                      </base-input>
                      <base-input label="CNPJ"
                                  type="tel"
                                  v-mask="['##.###.###/####-##']"
                                  name="CPF/CNPJ"
                                  :rules="{required: true}"
                                  v-model="account.socialNumber"
                                  key="social-number-cnpj"
                                  :disabled="profileFormFieldsDisabled"
                                  v-if="account.personType!='NATURAL_PERSON'">
                      </base-input>
                    </div>
                  </div>
                  <div v-if="account.personType=='LEGAL_PERSON'">
                    <div class="row">
                      <div class="col-md-12">
                        <base-input type="text"
                                    label="Razão Social"
                                    maxlength="100"
                                    :rules="{required: true}"
                                    v-model="account.legalPerson.companyName"
                                    :disabled="profileFormFieldsDisabled">
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-7">
                        <base-input type="text"
                                    maxlength="50"
                                    label="Atividade Principal"
                                    :rules="{required: true}"
                                    v-model="account.legalPerson.mainActivity"
                                    v-if="!profileFormFieldsDisabled">
                        </base-input>
                      </div>
                      <div class="col-md-5">
                        <div class="row" style="padding-left: 15px">
                          <base-input type="tel"
                                      v-mask="['##/##/####']"
                                      :rules="{required: true}"
                                      label="Data de Constituição"
                                      v-model="account.legalPerson.openingDate"
                                      v-if="!profileFormFieldsDisabled">
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="account.personType=='NATURAL_PERSON'">
                    <div class="row">
                      <div class="col-md-7">
                        <base-input type="text"
                                    maxlength="40"
                                    label="Naturalidade"
                                    :rules="{required: true}"
                                    v-model="account.naturalPerson.birthplace"
                                    :disabled="profileFormFieldsDisabled">
                        </base-input>
                      </div>
                      <div class="col-md-5">
                        <div class="row" style="padding-left: 15px">
                          <base-input type="tel"
                                      v-mask="['##/##/####']"
                                      label="Data de Nascimento"
                                      :rules="{required: true}"
                                      v-model="account.naturalPerson.birthDate"
                                      :disabled="profileFormFieldsDisabled">
                          </base-input>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"
                           :style="account.naturalPerson.identificationDocument.documentType!='RG'?'margin-bottom: 10px':''">
                        <base-input label="Tipo de Documento" key="tipo-documento">
                          <el-select size="large"
                                     placeholder="Selecionar"
                                     v-model="account.naturalPerson.identificationDocument.documentType"
                                     :disabled="profileFormFieldsDisabled">
                            <el-option class="select-success"
                                       value="RG"
                                       label="RG"/>
                            <el-option class="select-success"
                                       value="CNH"
                                       label="CNH"/>
                            <el-option class="select-success"
                                       value="PASSPORT"
                                       label="Passaporte"/>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="col-md-8" v-if="account.naturalPerson.identificationDocument.documentType=='RG'">
                        <base-input type="text"
                                    maxlength="40"
                                    label="Orgão Expedidor"
                                    v-model="account.naturalPerson.identificationDocument.dispatchingAgency"
                                    :disabled="profileFormFieldsDisabled">
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <base-input type="text"
                                    maxlength="20"
                                    label="Número do Documento"
                                    :rules="{required: true}"
                                    v-model="account.naturalPerson.identificationDocument.number"
                                    :disabled="profileFormFieldsDisabled">
                        </base-input>
                      </div>
                      <div class="col-md-6">
                        <div class="row" style="padding-left: 15px">
                          <base-input type="tel"
                                      v-mask="['##/##/####']"
                                      label="Data de Expedição"
                                      :rules="{required: true, min:10}"
                                      v-model="account.naturalPerson.identificationDocument.emissionDate"
                                      :disabled="profileFormFieldsDisabled"></base-input>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="margin-bottom: 10px">
                      <div class="col-md-4">

                        <base-input label="Sexo" key="sexo">
                          <el-select size="large"
                                     placeholder="Selecionar"
                                     v-model="account.naturalPerson.gender"
                                     :disabled="profileFormFieldsDisabled">
                            <el-option class="select-success"
                                       value="MALE"
                                       label="Masculino"/>
                            <el-option class="select-info"
                                       value="FEMALE"
                                       label="Feminino"/>
                          </el-select>
                        </base-input>

                      </div>
                      <div class="col-md-8">
                        <base-input label="Estado Civil" key="Estado Civil">
                          <el-select size="large"
                                     placeholder="Selecionar"
                                     v-model="account.naturalPerson.maritalStatus"
                                     :disabled="profileFormFieldsDisabled">
                            <el-option class="select-info"
                                       value="SINGLE"
                                       label="Solteiro"/>
                            <el-option class="select-danger"
                                       value="MARRIED"
                                       label="Casado"/>
                            <el-option class="select-success"
                                       value="DIVORCED"
                                       label="Divorciado"/>
                            <el-option class="select-warning"
                                       value="WIDOWED"
                                       label="Viúvo"/>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                    <div class="row" v-if="account.naturalPerson.maritalStatus=='MARRIED'">
                      <div class="col-md-12">
                        <base-input type="text"
                                    maxlength="70"
                                    label="Nome do Cônjuge"
                                    v-model="account.naturalPerson.partnerName"
                                    :disabled="profileFormFieldsDisabled"
                                    key="conjuge">
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input type="text"
                                    maxlength="30"
                                    label="Profissão"
                                    :rules="{required: true}"
                                    v-model="account.naturalPerson.profession"
                                    :disabled="profileFormFieldsDisabled">
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input type="text"
                                    maxlength="70"
                                    label="Nome da Mãe"
                                    :rules="{required: true}"
                                    v-model="account.naturalPerson.motherName"
                                    :disabled="profileFormFieldsDisabled">
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input type="text"
                                    maxlength="70"
                                    label="Nome do Pai"
                                    v-model="account.naturalPerson.fatherName"
                                    :disabled="profileFormFieldsDisabled">
                        </base-input>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 30px">
                    <div class="col-md-5">
                      <base-input type="tel"
                                  v-mask="['#####-###']"
                                  label="CEP"
                                  :rules="{required: true}"
                                  v-on:blur="searchZipCode"
                                  v-model="account.address.zipCode"
                                  :disabled="profileFormFieldsDisabled">
                      </base-input>
                    </div>
                    <div class="col-md-7">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <base-input type="text"
                                  maxlength="40"
                                  :disabled="((viacep && viacep.logradouro) || profileFormFieldsDisabled)?true:false"
                                  label="Rua/Av."
                                  v-model="account.address.street">
                      </base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input type="tel"
                                  v-mask="['######']"
                                  label="Número"
                                  v-model="account.address.number"
                                  :disabled="profileFormFieldsDisabled"
                                  key="numero">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input type="text"
                                  maxlength="40"
                                  label="Complemento"
                                  v-model="account.address.complement"
                                  :disabled="profileFormFieldsDisabled"
                                  key="complemento">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <base-input type="text"
                                  maxlength="40"
                                  :rules="{required: true}"
                                  :disabled="((viacep && viacep.bairro) || profileFormFieldsDisabled)?true:false"
                                  label="Bairro"
                                  v-model="account.address.neighborhood">
                      </base-input>
                    </div>
                    <div class="col-md-5">
                      <base-input type="text"
                                  label="Cidade"
                                  :disabled="true"
                                  v-model="account.address.city"
                                  key="cidade">
                      </base-input>
                    </div>
                    <div class="col-md-2">
                      <base-input type="text"
                                  label="Estado"
                                  :disabled="true"
                                  placeholder=""
                                  v-model="account.address.state"
                                  key="estado">
                      </base-input>
                    </div>
                  </div>
                  <br/>
                  <div class="row" v-if="showAgreementCb">
                    <div class="col-md-5">
                      <base-checkbox v-model="acceptTerms">
                        Eu aceito os <a v-bind:href="urlAgreement" target="_blank">termos e condições</a>.
                      </base-checkbox>
                    </div>
                  </div>
                  <br/>
                  <div class="text-center" v-if="!profileFormFieldsDisabled">
                    <button type="submit" class="btn btn-info btn-fill btn-wd"
                            :disabled="profileFormSaveButtonDisabled">
                      Salvar Dados
                    </button>
                  </div>
                  <div class="clearfix"></div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ResetPassword from './ResetPassword.vue';
  import {Option, Select} from 'element-ui'
  import UploadDoc from "../FileUpload/UploadDoc"
  import {callWs, failWs, getWs, postWs} from '@/ws.service'
  import {isNullOrEmpty} from '@/util/core.utils'

  export default {
    components: {
      ResetPassword,
      UploadDoc,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        file: '',
        showProfileForm: false,
        showDocumentsForm: false,
        usernameDisplay: '',
        email: '',
        statusAccount: '',
        avatarBase64: '',
        avatarFileType: '',
        showAvatar: false,
        viacep: {
          logradouro: '',
          bairro: ''
        },
        profileFormSaveButtonDisabled: false,
        profileFormFieldsDisabled: false,
        showAgreementCb: true,
        acceptTerms: false,
        urlAgreement: 'https://web.olimpopay.com.br/docs/pdf/CONTRATO_REDFOX_PF_1.0.pdf',
        account: {
          name: '',
          socialNumber: '',
          personType: 'NATURAL_PERSON',
          phoneNumber1: '',
          phoneNumber2: '',
          accountDocumentStatus: '',
          address: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          },
          legalPerson: {
            companyName: '',
            openingDate: '',
            mainActivity: ''
          },
          naturalPerson: {
            motherName: '',
            fatherName: '',
            birthDate: '',
            birthplace: '',
            gender: 'MALE',
            maritalStatus: 'SINGLE',
            partnerName: '',
            profession: '',
            identificationDocument: {
              documentType: 'RG',
              number: '',
              emissionDate: '',
              dispatchingAgency: ''
            }
          }
        },
      }
    },
    created() {
      this.usernameDisplay = "@" + localStorage.getItem("usernameDisplay")
      this.account.name = localStorage.getItem("name")
      this.email = localStorage.getItem("email")
      this.avatarBase64 = localStorage.getItem("avatarBase64")
      this.avatarFileType = localStorage.getItem("avatarFileType")
      if (isNullOrEmpty(this.avatarFileType)) {
        this.showAvatar = false
      } else {
        this.showAvatar = true
      }

      this.loadAccount()
    },
    methods: {
      loadAccount() {
        postWs("/acc/get-account", true, null, null,
          (response) => {
            this.account = response.data
            localStorage.setItem('name', this.account.name)
            this.showProfileForm = false
            this.showDocumentsForm = false
            if (this.account.accountDocumentStatus == 'APPROVED') {
              this.showProfileForm = true
              this.profileFormSaveButtonDisabled = true
              this.profileFormFieldsDisabled = true
              this.statusAccount = 'Conta Ativa'
            } else if (this.account.accountDocumentStatus == 'PENDING_DOCUMENT') {
              this.statusAccount = 'Pendente de Documentação'
            } else if (this.account.accountDocumentStatus == 'PENDING_VALIDATION') {
              this.statusAccount = 'Conta em Análise'
            }
            if (!isNullOrEmpty(this.account.address.codIbge)) {
              this.viacep.logradouro = this.account.address.street
              this.viacep.bairro = this.account.address.neighborhood
            }
            if (this.account.personType === 'NATURAL_PERSON' && !isNullOrEmpty(this.account.naturalPerson.motherName)) {
              this.showAgreementCb = false;
            } else if (this.account.personType === 'LEGAL_PERSON' && !isNullOrEmpty(this.account.legalPerson.mainActivity)) {
              this.showAgreementCb = false;
            }
          }, failWs)
      },
      saveAccount() {
        this.profileFormSaveButtonDisabled = true
        if (isNullOrEmpty(this.account.address.codIbge)) {
          this.$swal({
            title: 'Aviso!',
            text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            timer: 10000,
            icon: 'warning'
          })
          this.profileFormSaveButtonDisabled = false
          return
        }
        if (this.showAgreementCb && !this.acceptTerms) {
          this.$swal({
            title: "Aviso",
            text: 'Você deve aceitar os Termos e Condições antes de prosseguir.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            timer: 5000,
            icon: 'warning'
          })
          this.profileFormSaveButtonDisabled = false
          return
        }
        this.profileFormSaveButtonDisabled = true
        postWs("/acc/save-account", true, null, this.account,
          () => {
            localStorage.setItem("name", this.account.name)
            this.profileFormSaveButtonDisabled = false
            this.loadAccount()
          }, (error) => {
            this.profileFormSaveButtonDisabled = false
            if (error.response.status == 304) {
              this.$swal({
                title: 'Aviso!',
                text: 'CPF/CNPJ já cadastrado.',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                timer: 5000,
                icon: 'warning'
              })

            } else {
              this.$swal({
                title: 'Falha!',
                text: 'Erro ao salvar perfil, confira os dados e tente novamente.',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger btn-fill'
                },
                timer: 5000,
                icon: 'error'
              })
            }
          })
      },
      searchZipCode() {
        this.viacep = null
        this.account.address.codIbge = ""
        this.account.address.city = ""
        this.account.address.state = ""
        if (/^[0-9]{5}-[0-9]{3}$/.test(this.account.address.zipCode)) {
          getWs("/acc/get-cep",
            true, {cep: this.account.address.zipCode},
            (response) => {
              this.viacep = response.data
              this.account.address.codIbge = this.viacep.ibge
              if (this.viacep.logradouro.length > 40) {
                this.account.address.street = this.viacep.logradouro.substr(0, 40)
              } else {
                this.account.address.street = this.viacep.logradouro
              }
              this.account.address.neighborhood = this.viacep.bairro
              this.account.address.city = this.viacep.localidade
              this.account.address.state = this.viacep.uf
            },
            (e) => {
              this.$swal({
                title: 'Aviso!',
                text: 'CEP não encontrado nos Correios, favor verifique os dados e tente novamente!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                timer: 10000,
                icon: 'warning'
              })
              return
            })
        }
      },
      changePersonType() {
        localStorage.setItem('personType', this.account.personType)
        if (this.account.personType === 'NATURAL_PERSON') {
          this.urlAgreement = 'https://web.olimpopay.com.br/docs/pdf/CONTRATO_REDFOX_PF_1.0.pdf';
        } else {
          this.urlAgreement = 'https://web.olimpopay.com.br/docs/pdf/CONTRATO_REDFOX_PJ_1.0.pdf';
        }
      },
      getError(fieldName) {
        console.log(fieldName)
      },
      openProfileForm() {
        this.showProfileForm = true
        this.showDocumentsForm = false

        this.profileFormFieldsDisabled = false
        this.profileFormSaveButtonDisabled = false
      },
      openDocumentsForm() {
        this.showProfileForm = false
        this.showDocumentsForm = true

        this.profileFormFieldsDisabled = true
        this.profileFormSaveButtonDisabled = true
      },
      validateFile(event, upload) {
        if (event.target.files.length <= 0) {
          return
        } else if (event.target.files[0].size > (this.fileMaxSize * 1024 * 1024)) {
          this.$swal({
            title: 'Aviso!',
            text: 'A imagem deve possuir no máximo ' + this.fileMaxSize.toString() + 'MB',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          return
        }
        upload.file = event.target.files[0]
        upload.filename = upload.file.name
        upload.styleClass = "btn btn-primary"
      },
      handleFileUpload() {
        this.file = this.$refs.file.files[0]
        this.$swal({
          title: 'Alterar Imagem do Perfil',
          text: "Deseja realmente atualizar a imagem do seu perfil?.",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Atualizar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result) {
            this.callUploadWsAvatar()
          }
        })
      },
      callUploadWsAvatar() {
        let formData = new FormData()
        formData.append("file", this.file)
        callWs("/document/upload", true,
          "POST", {'Content-Type': 'multipart/form-data'},
          true, {fileType: 'AVATAR'}, formData,
          response => {
            this.$swal({
              title: 'Sucesso!',
              text: 'Imagem salva com sucesso.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              timer: 3000,
              icon: 'success'
            })
            localStorage.setItem("avatarBase64", response.data.imageBase64)
            localStorage.setItem("avatarFileType", response.data.imageFileType)
            setTimeout(() => {
              this.$router.go(0)
            }, 3000)
          },
          (error) => {
            let text = 'Falha ao salvar a imagem. Por favor tente novamente!'
            if (error.response.data.descriptionError === 'WRONG_DATA_SIZE') {
              text = 'Falha ao salvar a imagem. Verifique o tamanho do arquivo e tente novamente!'
            } else if (error.response.data.descriptionError === 'WRONG_DATA_FORMAT') {
              text = 'Falha ao salvar a imagem. Verifique o formato do arquivo e tente novamente!'
            } else if (error.response.data.descriptionError === 'USER_ACCOUNT_NOT_FOUND') {
              text = 'Falha ao salvar a imagem. Por favor, termine o preenchimento dos dados de seu cadastro antes de alterar sua imagem de avatar!'
            }
            this.$swal({
              title: 'Falha!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger btn-fill'
              },
              timer: 5000,
              icon: 'error'
            })
          }
        )
      },
      loadDocSrc() {
        let fileType = this.avatarFileType.replace(" ", "").toLowerCase()
        if (fileType == 'jpg') {
          return 'data:image/jpeg;base64,' + this.avatarBase64
        }
        return 'data:image/' + fileType + ';base64,' + this.avatarBase64
      },
    }
  }

</script>
<style lang="scss">
  a.fileContainer {
    cursor: pointer;
    display: inline-block;
    color: lightskyblue;
  }

  a.fileContainer > input[type=file] {
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    top: -40px;
    left: -20px;
    width: 140px;
    height: 120px;
    text-align: right;
    background: #000;
    border: solid 1px #000;
  }
</style>
